<template>
  <div>
    <h2>CARI ASN</h2>

    <v-divider class="mt-3 mb-7"></v-divider>

    <CAddASN v-if="READ_ASN_ALL_OPD || READ_ASN_GROUP_OPD" />

    <div>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="valueCari"
            label="Search"
            prepend-inner-icon="mdi-magnify"
            solo
            hide-details
            v-on:keyup.enter="cari"
            dense
            @click:prepend-inner="cari"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" v-if="READ_ASN_ALL_OPD || READ_ASN_GROUP_OPD">
          <v-autocomplete
            solo
            dense
            :items="refUnor"
            label="Unit Organisasi"
            item-value="idUnor"
            item-text="nmUnor"
            @change="filterUnor"
            return-object
          ></v-autocomplete>
        </v-col>
      </v-row>
    </div>

    <div class="mt-10">
      <v-card class="mb-10">
        <v-data-table
          :headers="headers"
          :search="search"
          :items="daftarASN"
          :mobile-breakpoint="0"
          :loading="loading"
          style="text-overflow: ellipsis; white-space: nowrap"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:[`item.foto`]="{ item }">
            <v-avatar size="40">
              <v-img :src="item.foto" alt="img" />
            </v-avatar>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              v-if="item.dataStatus == 'active'"
              x-small
              color="primary"
              dark
            >
              {{ item.dataStatus }}
            </v-chip>
            <v-chip v-else x-small color="error" dark>
              {{ item.dataStatus }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  depressed
                  color="primary"
                  @click="profil(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-account-details
                </v-icon>
              </template>
              <span>Profil Pegawai</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  depressed
                  class="mx-2"
                  color="primary"
                  @click="getRH(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-file-document-outline
                </v-icon>
              </template>
              <span>Riwayat Hidup</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getReferensi.js";
// import Auth from '@/helper/auth.js'

import CAddASN from "@/components/cari/tambah-asn";

export default {
  components: {
    CAddASN,
  },

  data: () => ({
    token: Cookie.get("token"),
    session: "",
    valueCari: "",

    showMassage: false,
    loading: false,
    loadingAdd: false,
    dialog: false,
    btnAdd: true,
    btnLoading: true,

    READ_ASN_ALL_OPD: false,
    READ_ASN_GROUP_OPD: false,

    daftarASN: [],
    refUnor: [],

    valueAdd: "",
    mapData: false,
    message: "",
    nipBaru: "",
    nama: "",
    valueCariRules: [
      (value) => !!value || "Silahkan Masukan NIP atau Nama ASN",
    ],

    search: "",
    headers: [
    //   {
    //     text: "Foto",
    //     align: "center",
    //     value: "foto",
    //     sortable: false,
    //     width: "90",
    //   },
      { text: "Nomor Induk Pegawai", align: "start", value: "nipBaru" },
      { text: "Nama Pegawai", value: "nama" },
      { text: "Unor", value: "unorNama" },
      { text: "Status", value: "status" },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        width: "130px",
        align: "center",
      },
    ],
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.refUnor = await getRef.newUnor(this.token);
    this.cekauth();
  },

  methods: {
    cari() {
      this.btnLoading = false;
      this.loading = true;
      const url = process.env.VUE_APP_ASN + "aparatur/cari/nip/" + this.valueCari;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.daftarASN = res.data.mapData;
          this.loading = false;
          this.btnLoading = true;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    async filterUnor(value) {
      this.loading = true;
      const url = process.env.VUE_APP_ASN + "aparatur/cari/opd/" + value.idUnor;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.loading = false;
            this.daftarASN = response.data.mapData;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status == 401) {
            // Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    profil(item) {
      this.$router.push("/pns/" + item.nipBaru);
    },

    getRH(item) {
      this.$router.push("/riwayathidup/" + item.nipBaru);
    },

    cekauth() {
      let arr = this.session.authorities;
      this.READ_ASN_ALL_OPD = arr.some((item) => item === "READ_ASN_ALL_OPD");
      this.READ_ASN_GROUP_OPD = arr.some(
        (item) => item === "READ_ASN_GROUP_OPD"
      );
    },
  },
};
</script>