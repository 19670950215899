<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="500">
      <template v-slot:activator="{ on: modal, attrs }">
        <v-tooltip top>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              color="primary"
              v-bind="attrs"
              fixed
              bottom
              right
              fab
              v-on="{ ...tooltip, ...modal }"
              @click="dialogtambahASN()"
            >
              <v-icon>mdi-account-multiple-plus</v-icon>
            </v-btn>
          </template>
          <span>Tambah Aparatur</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title>
          <span class="headline">Tambah Aparatur</span>
        </v-card-title>
        <v-divider class="mb-5"></v-divider>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="vnip"
              outlined
              dense
              label="NIP ASN"
              color="primary"
              :loading="loadingAdd"
              :rules="fRules"
              required
            ></v-text-field>

            <v-text-field
              v-model="vnama"
              outlined
              dense
              label="Nama ASN"
              color="primary"
              :loading="loadingAdd"
              :rules="fRules"
              required
            ></v-text-field>

            <v-select
              v-model="vjappr"
              outlined
              dense
              :items="j_aparatur"
              label="Jenis Aparatur"
              color="primary"
              :loading="loadingAdd"
              :rules="fRules"
              required
            ></v-select>
          </v-form>

          <v-divider class="mb-5"></v-divider>

          <v-card outlined v-if="showMassage">
            <v-card-title class="subtitle-2 error--text"
              >{{ message }}
            </v-card-title>
            <v-card-text v-if="mapData">
              <div>NIP : {{ nipBaru }}</div>
              <div>Nama : {{ nama }}</div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false"> Tutup </v-btn>
          <v-btn
            v-if="btnAdd"
        
            color="primary"
        
            @click="addASN()"
          >
            Tambah
          </v-btn>
          <v-btn v-else color="primary" loading>Tambah</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getReferensi.js";
// import Auth from '@/helper/auth.js'

export default {
  data: () => ({
    token: Cookie.get("token"),
    session: "",
    valueCari: "",

    showMassage: false,
    loading: false,
    loadingAdd: false,
    dialog: false,
    btnAdd: true,
    btnLoading: true,

    refUnor: [],
    j_aparatur: ["PNS", "PPPK"],

    vnip: "",
    vnama: "",
    vjappr: "",
    fRules: [(v) => !!v || "Tidak Boleh Kosong !"],

    mapData: false,
    message: "",
    nipBaru: "",
    nama: "",
  }),

  methods: {
    async dialogtambahASN() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.refUnor = await getRef.newUnor(this.token);
      this.dialog = true;
    },

    validate() {
        return this.$refs.form.validate();
    },

    addASN() {
      if (this.validate()) {
        this.loadingAdd = true;
        this.btnAdd = false;
        const url = process.env.VUE_APP_ASN + "aparatur/add";

        let data = {
          nipBaru: this.vnip,
          nama: this.vnama,
          statusASN: this.vjappr,
        };
        this.http
          .post(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((response) => {
            this.message = response.data.message;
            if (response.data.mapData != null) {
              this.nipBaru = response.data.mapData.nipBaru;
              this.nama = response.data.mapData.nama;
              this.mapData = true;
            } else {
              this.mapData = false;
            }
            this.loadingAdd = false;
            this.btnAdd = true;
            this.showMassage = true;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              // Auth.logout(this.token)
            } else if (error.response) {
              console.log(error.response.status);
            } else {
              console.log("Error", error.message);
            }
          });
      }
    },
  },
};
</script>